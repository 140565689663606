export const breakpoints = {
	tiny: "340px",
	small: "512px",
	medium: "768px",
	large: "1025px",
	xlarge: "1460px",
};

export const mq = {
	small: `only screen and (min-width: ${breakpoints.small})`,
	medium: `only screen and (min-width: ${breakpoints.medium})`,
	large: `only screen and (min-width: ${breakpoints.large})`,
	xlarge: `only screen and (min-width: ${breakpoints.xlarge})`,
	andMinHeight: `and (min-height: 400px)`,
};

export const grid = {
	width: "1220px",
};
