import { TimeOfDay } from "../models/TimeOfDay.model";

export const useGetLocalTime = (
	timeHoursOveride?: number | null
): TimeOfDay => {
	const currentHour = timeHoursOveride || new Date().getHours();

	if (currentHour <= 12) {
		return "morning";
	} else if (currentHour <= 16) {
		return "afternoon";
	} else if (currentHour <= 20) {
		return "evening";
	} else if (currentHour <= 24) {
		return "night";
	}
	return "morning";
};
