import styled, { css } from "styled-components";

const MainLayoutStyled = styled.main`
	${({ theme }) => {
		return css`
			display: flex;
			width: 100%;
			max-width: 1200px;
			height: 100vh;
			align-items: center;
			padding: 0;
			margin: 0 auto;
			/* padding: 1.6rem 0; */

			@media ${theme.mq.xlarge} {
				max-width: 1400px;
			}

			.main-layout__content {
				margin: 0 2.2rem;
				width: 100%;
				max-width: 50rem;
			}

			/* @media ${theme.mq.medium}${theme.mq.andMinHeight} {
				height: 100vh;
				align-items: center;
				padding: 0;
			} */

			/* .main-layout__content {
				margin: 0 2.2rem;
				width: 100%;

				@media ${theme.mq.medium} {
					width: auto;
					min-width: 50rem;
				}
			} */

			/* .card {
				position: absolute;
				top: 2.2rem;
				right: 2.2rem;
				bottom: 2.2rem;
				left: 2.2rem;
				max-width: 65rem;
				max-height: 60rem;
				margin: auto;
				overflow: scroll;

				@media ${theme.mq.medium}${theme.mq.andMinHeight} {
					position: relative;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
			} */
		`;
	}};
`;

export default MainLayoutStyled;
