import { BaseSwatchModel, PaletteSwatchModel } from "./models/color.model";

const base: BaseSwatchModel = {
	black: "#1A1A1A",
	white: "#FFFFFF",
	offWhite: "#f9f9f9",
	grey: "#333333",
	lightGrey: "#d9d9d9",
	link: "#1A1A1A",
	linkHover: "#00b3b3",
};

export const color: PaletteSwatchModel = {
	base,
};
