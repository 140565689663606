import React from "react";
import DynamicBackgroundStyled from "./DynamicBackground.styled";
import { DynamicBackgroundProps } from "./models/DynamicBackground.props.model";
import { useGetLocalTime } from "./util/useGetLocalTime";

// Wrapper component which loads a differnet gradient
// background depending current hour of the day

const DynamicBackground = ({
	children,
	timeHoursOveride,
	className,
}: DynamicBackgroundProps) => {
	const timeOfDay = useGetLocalTime(timeHoursOveride);

	return (
		<DynamicBackgroundStyled timeOfDay={timeOfDay} className={className}>
			{children}
		</DynamicBackgroundStyled>
	);
};

export default DynamicBackground;
