import { GradientsSchemaModel } from "./models/GradientsSchema.model";

export const gradientsSchema: GradientsSchemaModel = {
	morning: {
		backgroundColor: "#2C3E50",
		linearGradient: "#2C3E50, #FD746C",
	},
	afternoon: {
		backgroundColor: "#2c3e50",
		linearGradient: "#2c3e50, #4ca1af",
	},
	evening: {
		backgroundColor: "#f56217",
		linearGradient: "#f56217, #0b486b",
	},
	night: {
		backgroundColor: "#232526",
		linearGradient: "#232526, #414345",
	},
};
