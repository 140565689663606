import React from "react";
import CardStyled from "./Card.styled";
import { CardProps } from "./models/Card.props.model";

const Card = ({ title, titleSub, children, links, className }: CardProps) => {
	return (
		<CardStyled className={className}>
			<span className="card__header">
				<h1 className="card__title">{title}</h1>
				{titleSub && <span className="card__title-sub">{titleSub}</span>}
			</span>
			<div className="card__content">
				{children && <div className="card__text">{children}</div>}

				{links.length > 0 && (
					<ul className="card__list">
						{links.map((link, ind) => (
							<li key={`card-link-${ind}`}>
								<a href={link.uri} target="_blank" rel="noopener noreferrer">
									{link.text}
								</a>
							</li>
						))}
					</ul>
				)}
			</div>
		</CardStyled>
	);
};

export default Card;
