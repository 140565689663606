import styled, { css } from "styled-components";

const CardStyled = styled.div`
	${({ theme }) => {
		return css`
			display: flex;
			flex-direction: column;
			background: ${theme.color.base.white};
			box-shadow: 0 0.6rem 1.5rem rgba(36, 37, 38, 0.08);
			border-radius: 0.8rem;

			.card__header {
				padding: 0.8rem 1.6rem;
				display: flex;
				flex-wrap: wrap;
				align-items: baseline;
				justify-content: space-between;
				background: ${theme.color.base.offWhite};
				border-bottom: 1px solid ${theme.color.base.lightGrey};
				border-top-left-radius: 0.8rem;
				border-top-right-radius: 0.8rem;
			}

			.card__content {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex: 1;
				padding: 0.8rem 1.6rem;
			}

			.card__title {
				font-size: 2.4rem;
				font-weight: 400;
				margin-right: 0.8rem;

				@media ${theme.mq.medium}${theme.mq.andMinHeight} {
					font-size: 3.4rem;
				}
			}

			.card__title-sub {
				font-size: 1.6rem;
				font-weight: 300;
				margin-bottom: 1.6rem;

				@media ${theme.mq.medium} {
					font-size: 2.2rem;
				}
			}

			.card__list {
				list-style: none;
				padding: 0;

				li {
					display: inline;
					margin-right: 0.8rem;

					&:not(:first-child) {
						&:before {
							content: "|";
							margin-right: 0.8rem;
						}
					}
				}
			}
		`;
	}};
`;

export default CardStyled;
