import React from "react";
import MainLayoutStyled from "./Main.layout.styled";
import { MainLayoutProps } from "./models/Main.layout.model";

const MainLayout = ({ children, className }: MainLayoutProps) => {
  return (
    <MainLayoutStyled className={className}>
      <div className="main-layout__content">{children}</div>
    </MainLayoutStyled>
  );
};

export default MainLayout;
