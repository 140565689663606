import React from "react";
import Card from "./components/card";
import DynamicBackground from "./components/dynamicBackground";
import MainLayout from "./layouts/mainLayout";

function App() {
	return (
		<DynamicBackground>
			<MainLayout>
				<Card
					title="Jack Walden"
					titleSub="Software Engineer"
					links={[
						{
							text: "LinkedIn",
							uri: "https://www.linkedin.com/in/jack-walden-71b12033",
						},
						{
							text: "GitHub",
							uri: "https://github.com/jrwalden",
						},
					]}
					className="card"
				/>
			</MainLayout>
		</DynamicBackground>
	);
}

export default App;
