import { createGlobalStyle } from "styled-components";
import { color } from "./color";

const GlobalStyles = createGlobalStyle`

    a*,
	*::before,
	*::after {
		box-sizing: inherit;
    }

    html {
        font-family: 'Roboto', sans-serif;
        font-size: 62.5%;
        line-height: 1.6;
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
        color: ${color.base.black};
    }

    body {
        margin: 0;
        font-size: 1.6rem;
    }
    
    a {
        color: ${color.base.link};

        &:hover {
            color: ${color.base.linkHover};
        }
    }
`;

export default GlobalStyles;
