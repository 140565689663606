import styled, { css } from "styled-components";
import { gradientsSchema } from "./gradients.schema";
import { TimeOfDay } from "./models/TimeOfDay.model";

const DynamicBackgroundStyled = styled.div<{ timeOfDay: TimeOfDay }>`
	${({ timeOfDay }) => {
		return css`
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -3;
			overflow: hidden;
			background-color: ${gradientsSchema[timeOfDay].backgroundColor};
			background-image: linear-gradient(
				to left,
				${gradientsSchema[timeOfDay].linearGradient}
			);

			&:before,
			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 320%;
				background-color: ${gradientsSchema[timeOfDay].backgroundColor};

				box-shadow: 0 0.6rem 1.5rem rgba(36, 37, 38, 0.7);
			}

			&:before {
				top: -170%;
				left: 0;
				z-index: -2;
				transform: rotate(45deg);
				background-image: linear-gradient(
					to right,
					${gradientsSchema[timeOfDay].linearGradient}
				);
			}

			&:after {
				top: -50%;
				left: -30%;
				z-index: -1;
				transform: rotate(-45deg);
				background-image: linear-gradient(
					to bottom,
					${gradientsSchema[timeOfDay].linearGradient}
				);
			}
		`;
	}};
`;

export default DynamicBackgroundStyled;
